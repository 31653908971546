import React from 'react';

import { WebImg } from '@components';
import { isIos } from '@utils/deviceTypeCheck';
import { agencyBannerWebImg } from './img/common';

const AgencySection = () => {
  return (
    <div
      className="w-full flex justify-center pt-0 xl:pt-10 pb-3 px-4 xl:px-0"
      style={{
        background:
          'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 18%, rgba(249,173,25,0.5) 100%)',
        marginTop: isIos() ? '-0.5px' : '' // Workaround for Iphone to prevent white line
      }}
    >
      <div className="max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px text-gray-darkest flex flex-col-reverse xl:flex-col items-center text-center xl:text-left">
        <div className="flex flex-col xl:flex-row items-center mb-3 xxl:mb-8 mt-4 xl:mt-0">
          <div className="font-bold font_30px xl:font_40px xxl:font_50px">
            <span>Tailor made for </span>
            <span className="playfair_display italic">Global </span>
            <span>Sellers and Agencies</span>
          </div>
          <div className="font-light xl:ml-5 mt-2 xl:mt-0 text-lg xxl:text-xl">
            Manage multiple Amazon marketplaces and Seller Central accounts in one global dashboard.
          </div>
        </div>
        <WebImg {...agencyBannerWebImg} className="w-full" alt="agency" imgClassName="w-full" />
      </div>
    </div>
  );
};

export default AgencySection;
