import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll';

import ROUTES from 'seller/routing';
import WebImg from '@components/common/WebpImg';
import FontButton from '@components/buttons/FontButton';
import logoWebImg from '@sm/img/logo';
import RoundedButton from '@componentsV2/buttons/RoundedButton';
import BurgerMenu from './BurgerMenu';
import {
  FEATURES_ANCHOR,
  HEADER_HEIGHT,
  HOMEPAGE_SCROLL_CONTAINER_ID,
  PRICING_ANCHOR
} from './consts';
import SidebarNavigation from './SidebarNavigation';

export const NAVIGATION_LINKS = [
  { id: 1, text: 'Demo', url: ROUTES.demo, reload: true },
  { id: 2, text: 'Agencies', url: ROUTES.agency },
  { id: 3, text: 'Features', anchor: FEATURES_ANCHOR },
  { id: 4, text: 'Pricing', anchor: PRICING_ANCHOR },
  { id: 5, text: 'Learn', url: ROUTES.learnMore }
  // { id: 6, text: 'Success Story' },
];

export const getNavigationLinks = (buttonClasses, location, onCLick = () => {}) => {
  return NAVIGATION_LINKS.map(item => {
    const button = (
      <FontButton key={item.id} className={buttonClasses} onClick={onCLick}>
        {item.text}
      </FontButton>
    );
    if (item.anchor) {
      if (location.pathname !== ROUTES.root) {
        return <Link to={`${ROUTES.root}#${item.anchor}`}>{button}</Link>;
      }
      return (
        <FontButton
          key={item.id}
          className={buttonClasses}
          onClick={() => {
            scroller.scrollTo(item.anchor, {
              containerId: HOMEPAGE_SCROLL_CONTAINER_ID,
              smooth: true
            });
            onCLick();
          }}
        >
          {item.text}
        </FontButton>
      );
    }
    if (item.reload) {
      return (
        <FontButton
          key={item.id}
          className={buttonClasses}
          onClick={() => {
            window.location.href = item.url;
          }}
        >
          {item.text}
        </FontButton>
      );
    }
    return (
      <Link to={item.url} key={item.id}>
        {button}
      </Link>
    );
  });
};

function HomepageHeader({ location }) {
  const [mobileMenu, setMobileMenu] = useState(false);

  const linksBlock = (
    <div className="hidden xl:flex items-center justify-between h-full gap-6 text-gray-darkest flex-shrink">
      {getNavigationLinks('font-medium text-lg mx-1', location)}
    </div>
  );

  return (
    <div
      className="px-5 xl:px-12 flex justify-center xl:w-full items-center fixed top-0 z-30 bg-white shadow w-screen"
      style={{ height: HEADER_HEIGHT }}
    >
      <SidebarNavigation open={mobileMenu} setOpen={setMobileMenu} />
      <div className="flex-grow max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px">
        <div className="w-full flex items-center justify-between flex-wrap p-0">
          <div className="w-full flex xl:w-auto justify-between">
            <Link to={ROUTES.root}>
              <WebImg {...logoWebImg} alt="logo" imgClassName="h-10 xl:h-12" />
            </Link>
            <BurgerMenu onClick={() => setMobileMenu(!mobileMenu)} />
          </div>
          {linksBlock}
          <div className="hidden xl:flex items-center">
            <Link to={ROUTES.login}>
              <RoundedButton text="Sign in" outlined className="mr-3" />
            </Link>
            <Link to={ROUTES.registration}>
              <RoundedButton text="Sign up" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

HomepageHeader.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
  scrollMode: PropTypes.bool,
  hideMenuItems: PropTypes.bool
};

HomepageHeader.defaultProps = {
  scrollMode: false,
  hideMenuItems: false
};

export default withRouter(HomepageHeader);
