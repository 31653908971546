import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import FontButton from '@components/buttons/FontButton';
import ROUTES from 'seller/routing';
import NewTabReference from '@components/common/NewTabReference';
import WebImg from '@components/common/WebpImg';
import {
  APPSTORE_LINK,
  COMPANY_ADDRESS,
  DEMO_LINK,
  FACEBOOK_LINK,
  LINKEDIN_LINK,
  PLAYMARKET_LINK,
  PRIVACY_AND_POLICY_LINK,
  SUPPORT_LINK,
  TERMS_AND_CONDITIONS_LINK,
  YOUTUBE_LINK
} from '@sm/consts';
import logoWebImg from '@sm/img/logo';
import { isSmallSize } from '@sm/WindowSizes';
import RoundedButton from '@componentsV2/buttons/RoundedButton';
import { getNavigationLinks } from './HomepageHeader';
import statsBarsGreen from './img/common/stats_bars_green.svg';
import facebookIcon from './img/common/facebook_icon.svg';
import youtubeIcon from './img/common/youtube_icon.svg';
import linkedinIcon from './img/common/linkedin_icon.svg';
import { appleStoreWebImg, googlePlayWebImg } from './img/common';

function HomepageFooter({ location }) {
  const linksBlock = (
    <div className="flex flex-col items-start justify-start gap-3 w-1/2 xl:w-auto">
      {getNavigationLinks('xl:text-sm', location)}
    </div>
  );

  const privacyLinksBlock = (
    <div className="flex flex-col items-start justify-start gap-3 w-1/2 xl:w-auto">
      <NewTabReference href={TERMS_AND_CONDITIONS_LINK}>
        <FontButton>Terms & Conditions</FontButton>
      </NewTabReference>
      <NewTabReference href={PRIVACY_AND_POLICY_LINK}>
        <FontButton>Privacy Policy</FontButton>
      </NewTabReference>
      <NewTabReference href={SUPPORT_LINK}>
        <FontButton>Support</FontButton>
      </NewTabReference>
    </div>
  );

  return (
    <div className="w-full flex flex-col items-center relative bg-gray-lightest text-gray-darkest">
      <div className="max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px w-full pb-8 xl:pb-16 px-6 xl:px-0 pt-8 xl:pt-12">
        <div className="w-full flex flex-wrap xl:flex-no-wrap justify-between xl:text-sm">
          <div className="w-full xl:w-auto mb-8 xl:mb-0">
            <Link to={ROUTES.root}>
              <WebImg {...logoWebImg} alt="logo" imgClassName="h-12" />
            </Link>
          </div>
          {linksBlock}
          {privacyLinksBlock}
          <div
            className="flex-shrink mt-8 xl:mt-0 w-1/2 xl:w-auto pr-4 xl:pr-0"
            style={{ maxWidth: isSmallSize() ? '' : '100px' }}
          >
            <div className="text-gray-dark mb-3">Our Location</div>
            <div className="text-left">{COMPANY_ADDRESS}</div>
          </div>
          <div className="flex-shrink mt-8 xl:mt-0 w-1/2 xl:w-auto">
            <div className="text-gray-dark mb-3">Download App</div>
            <NewTabReference href={PLAYMARKET_LINK}>
              <WebImg
                {...googlePlayWebImg}
                className="w-32 sm-h-39px block mb-1"
                alt="play-market"
              />
            </NewTabReference>
            <NewTabReference href={APPSTORE_LINK}>
              <WebImg {...appleStoreWebImg} className="w-32 sm-h-39px block" alt="app-store" />
            </NewTabReference>
          </div>
          <div className="w-full mt-10 flex items-center justify-center xl:hidden">
            <Link to={ROUTES.registration} className="w-full">
              <RoundedButton
                text="TRY SELLERMETRIX FOR 30 DAYS—FREE!"
                wrapperClasses="w-full"
                className="w-full"
                baseClasses="font-bold text-lg px-3 py-3 rounded-xl"
              />
            </Link>
          </div>
          <div
            className="hidden w-64 mt-3 ml-5 items-start xl:flex bg-green-emerald text-white pl-5 pt-4 rounded-2xl relative"
            style={{ height: '99px' }}
          >
            <div className="flex flex-col items-start mb-2 ">
              <div className="text-sm">Drive More Traffic and Sales</div>
              <NewTabReference href={DEMO_LINK}>
                <RoundedButton
                  text="Start Demo"
                  className="mt-3"
                  bgColorClass="bg-yellow"
                  borderColorClass="border-yellow"
                  textColorClass="text-white font-medium"
                  baseClasses="text-xs rounded-xl py-2 px-5"
                />
              </NewTabReference>
            </div>
            <img src={statsBarsGreen} alt="stats-bars" className="absolute bottom-0 right-0 mr-8" />
          </div>
        </div>
        <div className="bg-gray-lighter_v2 rounded-2xl mt-12 px-10 py-3 flex justify-between items-center flex-wrap">
          <div className="w-full xl:w-auto text-center">
            ©{new Date().getFullYear()} SellerMetrix. All Rights Reserved.
          </div>
          <div className="flex justify-center w-full xl:w-auto gap-2 mt-2 xl:mt-0">
            <NewTabReference href={FACEBOOK_LINK}>
              <img src={facebookIcon} alt="facebook" />
            </NewTabReference>
            <NewTabReference href={YOUTUBE_LINK}>
              <img src={youtubeIcon} alt="youtube" />
            </NewTabReference>
            <NewTabReference href={LINKEDIN_LINK}>
              <img src={linkedinIcon} alt="linkedin" />
            </NewTabReference>
          </div>
        </div>
      </div>
    </div>
  );
}

HomepageFooter.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(HomepageFooter);
