import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { PlayIcon } from '../icons';
import FontButton from './FontButton';
import VideoDialog from '../dialogs/VideoDialog';

const VideoButton = ({ className, src, text, firstLine, secondLine }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FontButton
        role="button"
        tabIndex="0"
        className={classNames('flex flex-row text-sm text-yellow items-center', className)}
        onClick={() => setOpen(true)}
      >
        <div className="flex-shrink-0 h-4 w-4 mr-2 bg-yellow round flex justify-center items-center">
          <PlayIcon fill="#ffffff" width="8px" height="8px" />
        </div>
        {text ? (
          <span className=" text-left flex-1">{text}</span>
        ) : (
          <div className="text-sm text-right flex xs:block items-center flex-col">
            <span className="flex-shrink-0">{firstLine} </span>
            <span className="flex-shrink-0">{secondLine}</span>
          </div>
        )}
      </FontButton>
      <VideoDialog src={src} onClose={() => setOpen(false)} open={open} />
    </>
  );
};

VideoButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
  firstLine: PropTypes.string,
  secondLine: PropTypes.string,
  src: PropTypes.string.isRequired
};

VideoButton.defaultProps = {
  className: '',
  text: '',
  firstLine: '',
  secondLine: ''
};

export default VideoButton;
