import React from 'react';
import { Element } from 'react-scroll';

import ExpensesIcon from './img/features/expenses_feature.svg';
import RoiIcon from './img/features/roi_feature.svg';
import VatIcon from './img/features/vat_feature.svg';
import UnlimitedAccountsIcon from './img/features/unlim_accounts_feature.svg';
import SecureIcon from './img/features/secure_feature.svg';
import GdprIcon from './img/features/gdpr_feature.svg';
import ProductComboIcon from './img/features/products_combo_feature.svg';
import BreakdownIcon from './img/features/breakdown_feature.svg';
import NetProfitIcon from './img/features/net_profit_feature.svg';
import IndiaIcon from './img/features/india_feature.svg';
import { FEATURES_ANCHOR } from './consts';

const featuresList = {
  'All Your Amazon Expenses': ExpensesIcon,
  'Return on Investment Calculation': RoiIcon,
  'VAT Calculation': VatIcon,
  'Unlimited Seller Central Accounts': UnlimitedAccountsIcon,
  'Secure Data Handling': SecureIcon,
  'GDPR Compliant': GdprIcon,
  'Analyze any Combination of Products': ProductComboIcon,
  'Detailed Costs Breakdown': BreakdownIcon,
  'Gross & Net Profit Calculation': NetProfitIcon,
  'IGST For Amazon India Sellers': IndiaIcon
};

function FeaturesBlock() {
  const featureListContent = Object.keys(featuresList).map(feature => (
    <div className="flex flex-col items-center px-4 xl:px-0 pt-8 h-48 xxl:mx-16" key={feature}>
      <img src={featuresList[feature]} alt="feature shrink-0" />
      <div className="text-center font-bold mt-4">{feature}</div>
    </div>
  ));

  return (
    <Element
      name={FEATURES_ANCHOR}
      className="w-full flex justify-center py-4 px-4 xl:px-0 text-gray-darkest xl:max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px"
    >
      <div className="flex flex-col items-stretch">
        <div className="flex flex-col items-center text-center">
          <div className="font-bold font_30px xl:font_40px xxl:font_50px mb-5">
            <span>Empower </span>
            <span className="playfair_display italic">Your Workflow </span>
            <span>with These Features</span>
          </div>
          <p className="font-light text-lg xxl:text-xl">
            Boost productivity with tools to simplify, organize, and elevate your workflow.
          </p>
        </div>
        <div className="mt-5 xl:mt-10 grid grid-cols-2 xl:grid-cols-5 w-full self-stretch">
          {featureListContent}
        </div>
      </div>
    </Element>
  );
}

export default FeaturesBlock;
