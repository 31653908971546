import React, { useState } from 'react';

import CheckBox from '@components/check-box';
import FlatButton from '@components/buttons/FlatButton';
import { isDemoBuild } from '@utils/build';
import RoundedButton from '@componentsV2/buttons/RoundedButton';

const DEFAULT_SETTINGS = {
  necessary: true,
  analytics: false,
  preferences: false,
  marketing: false
};

const ENABLED_ALL_SETTINGS = Object.keys(DEFAULT_SETTINGS).reduce(
  (acc, key) => ({ ...acc, [key]: true }),
  {}
);

const REJECT_ALL_SETTINGS = Object.keys(ENABLED_ALL_SETTINGS).reduce(
  (acc, key) => ({ ...acc, [key]: false }),
  {}
);

const LOCAL_STORAGE_KEY = 'consentMode';

const Consent = () => {
  // https://github.com/googleanalytics/ga4-tutorials/blob/main/src/public/partials/consent.eta
  const localStorageSettings = localStorage.getItem(LOCAL_STORAGE_KEY);
  const [settings, _setSettings] = useState({ ...DEFAULT_SETTINGS });
  const [hidden, setHidden] = useState(!!localStorageSettings || isDemoBuild());
  const setSettings = (name, value) => _setSettings(prev => ({ ...prev, [name]: value }));
  const onApply = config => {
    const consentMode = {
      functionality_storage: config.necessary ? 'granted' : 'denied',
      security_storage: config.necessary ? 'granted' : 'denied',
      ad_storage: config.marketing ? 'granted' : 'denied',
      analytics_storage: config.analytics ? 'granted' : 'denied',
      personalization_storage: config.preferences ? 'granted' : 'denied',
      ad_user_data: config.marketing ? 'granted' : 'denied',
      ad_personalization: config.marketing ? 'granted' : 'denied'
    };
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(consentMode));
    if (window.gtag) window.gtag('consent', 'update', consentMode);
    setHidden(true);
  };

  if (hidden) return null;
  return (
    <div
      className="fixed right-0 bottom-0 z-9999 mr-6 mb-6 shadow-2xl flex flex-col justify-center items-start bg-white py-6 px-8 rounded"
      style={{ width: '345px' }}
    >
      <div className="text-lg mb-3 font-bold">Cookie Policy</div>
      <div className="">
        We use cookies to provide you with the best possible experience. They also allow us to
        analyze user behavior in order to constantly improve the website for you.
      </div>
      <div className="flex flex-col items-start gap-4 mt-4">
        <CheckBox label="Necessary" value disabled />
        <CheckBox
          label="Analytics"
          value={settings.analytics}
          onCheckHandler={value => setSettings('analytics', value)}
        />
        <CheckBox
          label="Preferences"
          value={settings.preferences}
          onCheckHandler={value => setSettings('preferences', value)}
        />
        <CheckBox
          label="Marketing"
          value={settings.marketing}
          onCheckHandler={value => setSettings('marketing', value)}
        />
      </div>
      <div className="flex gap-2 mt-4 w-full">
        <RoundedButton
          text="Accept Selection"
          onClick={() => onApply(settings)}
          outlined
          baseClasses="font-medium py-2 px-2 rounded-xl w-full"
          wrapperClasses="w-full flex-grow"
        />
        <RoundedButton
          text="Accept All"
          onClick={() => onApply(ENABLED_ALL_SETTINGS)}
          baseClasses="font-medium py-2 px-4 rounded-xl w-full"
          wrapperClasses="w-full flex-grow"
        />
      </div>
      <FlatButton
        onClick={() => onApply(REJECT_ALL_SETTINGS)}
        className="self-center mt-3 underline text-gray-dark"
      >
        Reject All
      </FlatButton>
    </div>
  );
};

export default Consent;
