/* eslint-disable global-require */

import { getWebpImg } from '@utils/import';

const images = {
  'logo-mobile.png': require('./logo-mobile.png'),
  'logo-mobile.webp': require('./logo-mobile.webp'),
  'logo.png': require('./logo.png'),
  'logo.webp': require('./logo.webp')
};
const logoWebImg = getWebpImg(images, 'logo');

export default logoWebImg;
