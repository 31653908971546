import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import Link from '@material-ui/core/Link';

import { isDemoBuild } from '@utils/build';
import { addInputFocusHandler } from '@utils/forms';
import { EnvelopeIcon, LockIcon } from '@components/icons';
import { BackIconButton, RoundedButton, GenerateInputs } from '@componentsV2';
import ROUTES from 'seller/routing';
import { ImageObserver } from 'seller/containers/observer';
import { getShopifyCallbackData } from 'seller/store/actions/shopify.actions';
import { loginAction, setWarningMsgAction } from 'seller/store/actions';
import { useTextInput } from 'seller/pages/profile/Hooks';
import signinWallpaper from './signin_wallpaper.png';

function LoginWindow({ login, token, username, history, location, onLoginPath, setMessage }) {
  const email = useTextInput(username);
  const password = useTextInput('');
  const inputRefs = useRef([]);

  function onLoginClick() {
    if (!email.value || !password.value) {
      return;
    }
    login(email.value, password.value, () => history.push(`${onLoginPath}${location.search}`));
  }

  const inputsList = [
    { icon: EnvelopeIcon, type: 'email', props: email, hint: 'Write your email' },
    { icon: LockIcon, type: 'password', props: password, hint: 'Set up your password' }
  ];

  addInputFocusHandler(inputsList, onLoginClick, inputRefs);
  useEffect(() => {
    if ((token && username) || isDemoBuild()) history.push(`${onLoginPath}${location.search}`);
    if (!getShopifyCallbackData(location)) return;
    setMessage('Please login for Shopify app installation');
  }, [history, location, onLoginPath, setMessage, token, username]);

  return (
    <ImageObserver className="flex bg white w-full sm-full-min-h-mobile">
      <div className="px-8 sm:px-12 pt-8 sm:pt-12 text-gray-darkest flex flex-col items-center sm:justify-between bg-white md:w-2/4 w-full box-shadow-default min-h-screen z-0">
        <Link component={RouterLink} to={ROUTES.root} className="self-start mb-12 sm:mb-0">
          <BackIconButton />
        </Link>
        <div className="flex flex-col items-start w-full sm:w-10/12 sm:sm-w-448px">
          <p className="font_40px font-bold mb-4">Sign in</p>
          <form className="w-full">
            <div className="flex flex-col gap-3">
              <GenerateInputs data={inputsList} styleV2 />
            </div>
            <div className="mt-5 flex items-center justify-between">
              <Link
                component={RouterLink}
                to={ROUTES.resetPassword}
                color="inherit"
                className="text-sm text-yellow font-medium"
              >
                Forgot password?
              </Link>
            </div>
            <RoundedButton text="Sign in" onClick={() => onLoginClick()} className="mt-4 w-full" />
          </form>
          <div className="flex justify-center items-center w-full mt-4">
            <div className="h-px bg-gray flex-grow" />
            <div className="mx-2 text-gray">or</div>
            <div className="h-px bg-gray flex-grow" />
          </div>
          <div className="mt-4 w-full flex items-center justify-center text-sm text-gray-dark font-bold">
            <p className="mr-1 inline-block">Don’t have an account?</p>
            <Link
              component={RouterLink}
              to={`${ROUTES.registration}${location.search}`}
              color="inherit"
              className="text-green-emerald font-bold"
            >
              Sign Up
            </Link>
          </div>
        </div>
        <div />
      </div>
      <div className="w-2/4 h-screen justify-start items-center hidden md:flex overflow-hidden">
        <img
          src={signinWallpaper}
          alt="signin-wallpaper"
          className="object-cover min-w-full min-h-full"
        />
      </div>
    </ImageObserver>
  );
}

LoginWindow.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired,
  login: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  setMessage: PropTypes.func.isRequired,
  username: PropTypes.string,
  onLoginPath: PropTypes.string
};

LoginWindow.defaultProps = {
  username: null,
  onLoginPath: ROUTES.home
};

function mapStateToProps(state) {
  return {
    username: state.login.username,
    token: state.login.token
  };
}

export default withRouter(
  connect(mapStateToProps, {
    login: loginAction,
    setMessage: msg => dispatch => dispatch(setWarningMsgAction(msg, false, false))
  })(LoginWindow)
);
