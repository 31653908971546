import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

import { HEADER_HEIGHT } from '@sm/layout';
import { GradButton } from '@components/buttons';
import img from './il_feature5.png';

const NoOrdersDialog = ({ open }) => {
  return (
    <Dialog
      style={{ paddingTop: HEADER_HEIGHT }}
      open={open}
      maxWidth="lg"
      PaperProps={{
        className: 'px-4 sm:px-12 lg:px-24 py-4 sm:py-8 lg:py-20',
        style: { maxWidth: '1175px' }
      }}
    >
      <div className="pl-4 mb-2 sm:mb-12">
        <h2 className="text-left text-3xl lg:text-5xl text-yellow mb-2 tracking-tight">
          No Amazon orders found
        </h2>
        <p className="text-gray-darkest lg:text-xl text-left tracking-wide pr-8">
          Seller Metrix is a software that helps Amazon sellers stay on top of their business by
          displaying their Sales, Profit, and many other business KPIs. But to start using Seller
          Metrix, you need at least 1 Amazon order in the past 60 days.
        </p>
      </div>
      <div className="flex justify-center sm:justify-between items-center lg:items-start pl-4 sm:flex-no-wrap flex-wrap-reverse">
        <div className="pt-2 sm:pt-8">
          <h3 className="text-left text-2xl lg:text-3xl text-yellow mb-2 sm:mb-4 tracking-wide">
            Want to start selling on Amazon?
          </h3>
          <p className="text-gray-darkest lg:text-xl text-left tracking-wide lg:w-10/12 mb-2 sm:mb-4">
            If you need help to get started on Amazon, check out this free course by Amazeowl and
            find your first product ideas in 5 days.
          </p>
          <a
            target="blink"
            href="https://amazeowlacademy.thinkific.com/courses/from-zero-to-launch?utm_source=SellerMetrix&utm_medium=partners&utm_campaign=free_course"
          >
            <GradButton grad="bg-grad-8" className="text-lg h-12 mb-4 sm:mb-10">
              Get Started
            </GradButton>
          </a>
          <p className="text-gray-darkest lg:text-xl text-left tracking-wide lg:w-10/12">
            Good luck, and see you again when you had your first Amazon order!
          </p>
        </div>
        <div
          className="w-8/12 sm:w-4/12 lg:w-1/2 my-auto sm:my-0  bg-center bg-no-repeat flex-shrink-0 relative overflow-hidden mr-4 sm:-mr-4"
          style={{ backgroundImage: `url(${img})`, backgroundSize: '130%' }}
        >
          <div className="h-0" style={{ paddingTop: '67%' }} />
        </div>
      </div>
    </Dialog>
  );
};

NoOrdersDialog.propTypes = {
  open: PropTypes.bool.isRequired
};

export default NoOrdersDialog;
