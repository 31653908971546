import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import RoundedButton from '@componentsV2/buttons/RoundedButton';
import WebImg from '@components/common/WebpImg';
import { PlayIcon } from '@components/icons';
import Colors from 'styles/colors';
import ROUTES from 'seller/routing';
import { workerLaptopWebImg } from './img/common';
import statsBarsYellow from './img/common/stats_bars_yellow.svg';

const UnderHeaderBlock = ({ title, description, getRegistrationButton }) => {
  const playButtonContent = (
    <div className="flex items-center">
      <PlayIcon fill={Colors.green.emerald} width="15px" height="18px" className="mr-3" />
      <span className="">Watch Demo</span>
    </div>
  );
  return (
    <div className="w-full flex-grow xl:grid grid-cols-2 py-6 xl:py-15 gap-6 xxl:gap-16 px-4 xl:px-0 text-gray-darkest max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px">
      <div className="flex flex-col justify-center items-start">
        <div className="font_35px xl:font_60px font-bold">{title}</div>
        <div className="mt-4 xl:text-xl xxl:text-2xl font-light mb-6">{description}</div>
        <div className="flex justify-center xl:justify-start gap-3 w-full">
          {getRegistrationButton()}
          <RoundedButton text={playButtonContent} outlined className="hidden" />
        </div>
      </div>
      <div className="hidden xl:block">
        <div className="flex gap-4">
          <WebImg
            {...workerLaptopWebImg}
            className="w-1/2"
            alt="worker-laptop"
            imgClassName="w-full"
          />
          <div className="w-1/2">
            <div className="flex flex-col items-start p-7.5 bg-amber-lighter rounded-3xl h-full w-full">
              <div className="text-6xl font-bold mb-2">1000+</div>
              <div className="mt-2 font-light xxl:text-xl xxl:max-w-2/3">
                Amazon Sellers who rely on SellerMetrix everyday.
              </div>
              <div
                className="flex flex-col items-start w-full bg-amber-light mt-auto"
                style={{ height: '13px', borderRadius: '100px' }}
              >
                <div
                  className="flex shrink-0 bg-yellow"
                  style={{ width: '65%', height: '13px', borderRadius: '100px' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex bg-yellow text-white pl-10 pt-9 rounded-3xl mt-4">
          <div>
            <div className="flex items-center mb-2">
              <div className="h-px bg-white w-12 mr-2" />
              <div className="text-lg xxl:text-xl">Drive More Traffic and Sales</div>
            </div>
            <div className="text-3xl xxl:text-4xl font-bold mb-8">
              Boost Traffic and Drive Product Sales for Your Amazon Business
            </div>
          </div>
          <img src={statsBarsYellow} alt="stats-bars" className="self-end mr-8 h-full w-2/3" />
        </div>
      </div>
    </div>
  );
};

UnderHeaderBlock.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  description: PropTypes.string.isRequired,
  getRegistrationButton: PropTypes.element
};

UnderHeaderBlock.defaultProps = {
  getRegistrationButton: () => (
    <Link to={ROUTES.registration}>
      <RoundedButton text="Start Free 30 Days Trial" />
    </Link>
  )
};

export default UnderHeaderBlock;
