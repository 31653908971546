import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { uiSelector } from 'seller/store/reducers';
import HomepageHeader from './HomepageHeader';
import { HEADER_HEIGHT, HOMEPAGE_SCROLL_CONTAINER_ID } from './consts';

const HeaderLayout = ({ children, scrollContainerRef }) => {
  const { viewportHeight } = useSelector(uiSelector);
  return (
    <div className="flex justify-center" style={{ height: viewportHeight }}>
      <HomepageHeader />
      <div
        id={HOMEPAGE_SCROLL_CONTAINER_ID}
        ref={scrollContainerRef}
        style={{ marginTop: HEADER_HEIGHT, height: `calc(${viewportHeight} - ${HEADER_HEIGHT}` }}
        className="relative w-full flex-grow overflow-auto"
      >
        {children}
      </div>
    </div>
  );
};

HeaderLayout.propTypes = {
  children: PropTypes.node.isRequired,
  scrollContainerRef: PropTypes.instanceOf(Object)
};

HeaderLayout.defaultProps = {
  scrollContainerRef: null
};

export default HeaderLayout;
