import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import classNames from 'classnames';

import { isSmallSize } from '@sm/WindowSizes';
import WebImg from '@components/common/WebpImg';
import TabSwitcher from './TabSwitcher';
import { getCarouselImg } from './img/carousel';
import { mobilePhoneWebImg } from './img/common';

export const featuresList = [
  {
    tabName: 'Real-Time Report',
    img: getCarouselImg('realtime'),
    title: 'Track Your Amazon Sales & Profit - In Real Time',
    description:
      'Instantly see your Amazon sales and profit in your personal dashboard. Track key financial metrics like Sales, Profit, PPC Spend, Amazon fees, ROI, and Refunds all in one place.'
  },
  {
    tabName: 'Gain Profit & Loss',
    img: getCarouselImg('pnl'),
    title: 'Gain a Complete View of Your Profit & Loss',
    description:
      'Keep track of your P&L with detailed insights on profitability, costs, refunds, and ROI. Connect multiple marketplaces to get a global view of your financial performance.'
  },
  {
    tabName: 'Traffic',
    img: getCarouselImg('traffic'),
    title: 'Discover What Keywords Drive Your Organic Sales',
    description:
      'Uncover the keywords bringing organic traffic to your listings with ease. View Search Query Performance Data and identify which keywords contribute the most to your sales.'
  },
  {
    tabName: 'Reviews & Email',
    img: getCarouselImg('email_follow'),
    title: 'Boost Reviews with Email Automation',
    description: `Automate your follow-up emails and "Request Review" processes, leveraging Amazon's internal systems. Fully ToS compliant, send unlimited emails with any Seller Metrix plan.`
  },
  {
    tabName: 'Mobile',
    img: mobilePhoneWebImg,
    title: 'Your Amazon Analytics On The Go',
    description:
      'Stay updated with real-time sales data via the Seller Metrix mobile app. Available for both iOS and Android, the app updates every 3 minutes, ensuring you never miss a sale.'
  }
];

export const FeaturesCarousel = () => {
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);
  const getSlideComponent = feature => {
    return (
      <div key={feature.title} className="px-4 xl:px-0 flex flex-col items-center w-full">
        <WebImg
          className={classNames(
            'w-full flex items-center justify-center py-8',
            feature.imgClassName || ''
          )}
          imgClassName="sm-h-224px xl:sm-h-480px xxl:sm-h-540px xxxl:sm-h-650px rounded-xl"
          alt="feature"
          {...feature.img}
        />
        <div className="w-full flex flex-col justify-start items-center mb-10 text-center">
          <div className="text-3xl xxl:text-4xl font-bold">{feature.title}</div>
          <p className="text-lg xxl:text-xl font-light mt-3 text-center">{feature.description}</p>
        </div>
      </div>
    );
  };

  return (
    <div
      className="w-full flex justify-center"
      style={{
        background:
          'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 12%, rgba(249,173,25,0.5) 100%)'
      }}
    >
      <div className="flex flex-col items-center mt-2 xl:mt-10 px-4 xl:px-0 w-screen xl:w-full xl:max_w_1116px xxl:max_w_1404px xxxl:max_w_1596px">
        <TabSwitcher
          changeActiveTab={newIndex => setSelectedFeatureIndex(newIndex)}
          options={featuresList.map(({ tabName }, i) => ({ text: tabName, value: i }))}
          activeTab={selectedFeatureIndex}
          className="mb-2"
        />
        <SwipeableViews
          index={selectedFeatureIndex}
          onChangeIndex={setSelectedFeatureIndex}
          springConfig={{
            duration: '0.8s',
            easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
            delay: '0s'
          }}
          containerStyle={{ width: isSmallSize() ? '100vw' : '100%' }}
        >
          {featuresList.map(slide => {
            return getSlideComponent(slide);
          })}
        </SwipeableViews>
      </div>
    </div>
  );
};
