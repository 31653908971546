export const PULL_PRODUCTS = 'PULL_PRODUCTS';
export const SET_PRODUCT_STATUS = 'SET_PRODUCT_STATUS';
export const SET_PRODUCT = 'SET_PRODUCT';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const START_PRODUCTS_LOADING = 'START_PRODUCTS_LOADING';
export const SET_MISSING_INFO_FLAG = 'SET_MISSING_INFO_FLAG';
export const UPDATE_PRODUCTS = 'UPDATE_PRODUCTS';
export const BULK_UPDATE_HCOGS = 'BULK_UPDATE_HCOGS';
export const PULL_PRODUCT_HCOGS = 'PULL_PRODUCT_HCOGS';
export const DELETE_HCOG_RANGE = 'DELETE_HCOG_RANGE';
export const SAVE_HCOG_RANGE = 'SAVE_HCOG_RANGE';
export const SET_BULK_UPLOADING = 'SET_BULK_UPLOADING';

export const pullProductsAction = payload => ({
  type: PULL_PRODUCTS,
  payload
});

export const setProductStatusAction = payload => ({
  type: SET_PRODUCT_STATUS,
  status: payload
});

export const setProductAction = payload => ({
  type: SET_PRODUCT,
  payload
});

export const setProductsAction = productsList => ({
  type: SET_PRODUCTS,
  productsList
});

export const startProductsLoadingAction = () => ({
  type: START_PRODUCTS_LOADING
});

export const setMissingInfoFlagAction = showOnlyWithMissingInfo => ({
  type: SET_MISSING_INFO_FLAG,
  showOnlyWithMissingInfo
});

export const updateProductsAction = payload => ({
  type: UPDATE_PRODUCTS,
  payload
});

export const bulkUpdateHcogsAction = payload => ({
  type: BULK_UPDATE_HCOGS,
  payload
});

export const pullProductHCogsAction = (payload, changeProductsCallback) => ({
  type: PULL_PRODUCT_HCOGS,
  payload,
  changeProductsCallback
});

export const deleteHcogRangeAction = (id, callback) => ({
  type: DELETE_HCOG_RANGE,
  id,
  callback
});

export const saveHcogRangeAction = (rangeData, callback) => ({
  type: SAVE_HCOG_RANGE,
  rangeData,
  callback
});

export const setBulkUploadingAction = () => ({ type: SET_BULK_UPLOADING });
