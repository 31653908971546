/* eslint-disable global-require */

const IMAGES = {
  email_follow: require('./email_followup.png'),
  email_follow_mobile: require('./email_followup-min.png'),
  email_follow_webp: require('./email_followup.webp'),
  email_follow_mobile_webp: require('./email_followup-min.webp'),
  mobile: require('./mobile.png'),
  mobile_mobile: require('./mobile-min.png'),
  mobile_webp: require('./mobile.webp'),
  mobile_mobile_webp: require('./mobile-min.webp'),
  pnl: require('./pnl.png'),
  pnl_mobile: require('./pnl-min.png'),
  pnl_webp: require('./pnl.webp'),
  pnl_mobile_webp: require('./pnl-min.webp'),
  realtime: require('./realtime.png'),
  realtime_mobile: require('./realtime-min.png'),
  realtime_webp: 'images/realtime.webp', // placed in public folder to prefetch
  realtime_mobile_webp: 'images/realtime-min.webp', // placed in public folder to prefetch
  traffic: require('./traffic.png'),
  traffic_mobile: require('./traffic-min.png'),
  traffic_webp: require('./traffic.webp'),
  traffic_mobile_webp: require('./traffic-min.webp'),
  agency: require('./agency.png'),
  agency_mobile: require('./agency-min.png'),
  agency_webp: require('./agency.webp'),
  agency_mobile_webp: require('./agency-min.webp')
};

export const getCarouselImg = name => ({
  src: IMAGES[name],
  srcWebp: IMAGES[`${name}_webp`],
  srcMobile: IMAGES[`${name}_mobile`],
  srcMobileWebp: IMAGES[`${name}_mobile_webp`]
});
