import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Div100vh from 'react-div-100vh';
import { useDispatch } from 'react-redux';

import { setViewportHeight } from 'seller/store/actions';
import { useNodeSize } from '@utils/hooks';

const FullHeightLayout = ({ children }) => {
  const { nodeRef, nodeHeight } = useNodeSize();
  const dispatch = useDispatch();
  useEffect(() => {
    if (nodeHeight) dispatch(setViewportHeight(`${nodeHeight}px`));
  }, [dispatch, nodeHeight]);
  return (
    <Div100vh>
      <div ref={nodeRef} className="w-full h-full absolute">
        {children}
      </div>
    </Div100vh>
  );
};

FullHeightLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export default FullHeightLayout;
