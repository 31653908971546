import React from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';

import LayoutView from './LayoutView';

export const RouteView = withRouter(
  React.memo(({ path, component, exact, ...other }) => {
    return (
      <Route
        path={path}
        exact={exact}
        component={() => {
          return <LayoutView>{React.createElement(component, { ...other })}</LayoutView>;
        }}
      />
    );
  })
);

RouteView.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  exact: PropTypes.bool
};

RouteView.defaultProps = {
  exact: false
};
