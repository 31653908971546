import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { scroller } from 'react-scroll';

import { isDemoBuild } from '@utils/build';
import { utLp2View } from '@utils/userTracking';
import ROUTES from 'seller/routing';
import { ImageObserver } from 'seller/containers/observer';
import { loginSelector } from 'seller/store/reducers';
import HomepageMainBlock from './HomepageMainBlock';
import HomepagePricing from './HomepagePricing';
import FeaturesBlock from './FeaturesBlock';
import { FeaturesCarousel } from './FeaturesCarousel';
import AgencySection from './AgencySection';
import WorldwideBlock from './WorldwideBlock';
import MobileBlock from './MobileBlock';
import HomepageFooter from './HomepageFooter';
import LearnMoreBlock from './LearnMoreBlock';
import { ALLOWED_SCROLL_HASHES, HOMEPAGE_SCROLL_CONTAINER_ID } from './consts';
import HeaderLayout from './HeaderLayout';

function Homepage({ history, location }) {
  const { token, username } = useSelector(loginSelector);
  if ((token && username) || isDemoBuild()) {
    history.push(ROUTES.home);
  }

  useEffect(() => {
    const clearedHash = location.hash?.replace('#', '');
    utLp2View();
    if (!ALLOWED_SCROLL_HASHES.includes(clearedHash)) return;
    scroller.scrollTo(clearedHash, {
      containerId: HOMEPAGE_SCROLL_CONTAINER_ID,
      smooth: true
    });
    history.replace(location.pathname);
  });

  return (
    <HeaderLayout>
      <ImageObserver
        className="w-full flex flex-col items-center"
        loadingClassname="overflow-hidden absolute w-screen h-screen"
      >
        <HomepageMainBlock />
        <FeaturesCarousel />
        <AgencySection />
        <FeaturesBlock />
        <WorldwideBlock />
        <MobileBlock />
        <HomepagePricing />
        <LearnMoreBlock />
        <HomepageFooter />
      </ImageObserver>
    </HeaderLayout>
  );
}

Homepage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  location: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(Homepage);
